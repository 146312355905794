@import '../template/main_setting';
$text_black: map-get($text, title_color);
$bg_gray: map-get($color_theme, gray);

.home-keyword {
    margin: 15px 0;

    @media (max-width: 768px) {

        margin: 5px;
        padding: 0;

        h3 {
            font-size: 18px;
        }
    }
}

.home {

    .gray-home1 {
        background: #efefef;
        padding: 20px 20px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .gray-home2 {
        background: $bg_gray;
        padding: 20px 20px;
        margin-left: -15px;
        margin-right: -15px;
    }

    &__title {
        color: $text_black;
    }
    &__detail {
        &__icon {
            padding-right: 5px;
        }
    }

    &-cert {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &-about {
        img {
            margin-bottom: 20px;
        }
    }

    &-news {
        img {
            border-radius: 4px;
        }
    }

    &-cert {
        img {
            padding: 20px;
            border-radius: 30px;
        }
    }

    .main_title {
        margin-bottom: 30px;
    }

    .readmore {
        color: $text_black;
        margin-right: 0;
        text-decoration: underline;
    }

    .home-end {
        margin: auto;
    }

    

    @media (max-width: 768px) {
        .home-customer {
            img {
                padding: 0 10px;
            }
        }

    }
    
}
