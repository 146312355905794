@function color_theme($color) {
    @return map-get($color_theme, $color);
}

//-- main color--//
$color_theme: (
    orange: #ff5800,
    white: #fff,
    grey: #f6f6f6,
    black: #000,
);
//---------------//

$text: (
    theme_color: color_theme(orange),
    title_color: color_theme(black),
    main_color: color_theme(black),
    main_size: 18px,
);

$header_jobthai: (
    background: color_theme(orange),
);

$footer_jobthai: (
    background: color_theme(orange),
    text: color_theme(white),
    text_size: 10px,
);

$body: (
    background: color_theme(white),
    max_width: 1366px,
);

$btn: (
    text: color_theme(white),
    background: color_theme(orange),
);

$navbar: (
    background: color_theme(white),
    border: color_theme(white),
    text: color_theme(black),
    text_active: color_theme(orange),
    collapse_border: #101010,
    list_border: #333,
    toggle_border: #333,
    toggle_background: #272727,
    toggle_icon: #000,
    footer_menu_background: #111,
);

$pagination: (
    text: #999,
    text_active: color_theme(white),
    background: color_theme(white),
    background_active: color_theme(orange),
    border: #ddd,
    border_active: #ddd,
);

$footer_custom: (
    background: color_theme(grey),
    text_color: color_theme(black),
    text_size: 16px,
);

$filter: (
    width: 100%,
    padding: 0,
    background_orange: color_theme(orange),
    background_white: color_theme(white),
    text_black: color_theme(black),
    text_gray: color_theme(gray),
    text_white: color_theme(white),
    font_size: 14px,
);

$panel_jobdetail: (
    background: #272727,
);



